$(function()
        {
            var back = $('#background');
            var menu = $('#menu');
            var pictures = $('#pictures li');
            if (pictures.length > 0)
            {
                var current = 0;

                $('#picture-next').on({
                    click : function()
                        {
                        $("#thumbnails img").removeClass("active_image");
                        current++;
                        if (current > pictures.length - 1) current = 0;
                        check_image(current);
                        var next = pictures.eq( current );
                        var src = $('img', next).attr('src');
                        back.attr('src', src);
                        location();
                        return false;
                    }
                });

                $('#picture-previous').on({
                    click : function()
                    {
                        current--;
                        $("#thumbnails img").removeClass("active_image");
                        if (current < 0) current = pictures.length - 1;
                        var prev = pictures.eq( current );
                        check_image(current);
                        var src = $('img', prev).attr('src');
                        $("#thumbnails img").eq(current).addClass("active_image");
                        back.attr('src', src).trigger('scale');
                        location();
                        return false;
                    }
            });

        //skrollera nopozicionēšana
           var location= function(px)
        {
             $("#thumbnails img").eq(current).addClass("active_image");
             $("#mTS_1_container li a").removeClass('active_thumb');
             $("#mTS_1_container li").eq(current).children("a").addClass("active_thumb");
            to = $(".active_thumb").position().left + ($(".active_thumb").width()/2)- ($(".mTSWrapper").width()/2);

        if(current == 0)
        {
            $(".content").mThumbnailScroller("scrollTo", "first");
        }
        else{
                $(".content").mThumbnailScroller("scrollTo", $(".active_thumb").position().left- ($(".mTSWrapper").width()/2));
            }
}


//funckcija, kas pārbauda, vai tagadējā, iepriekšējā un nākošā bilde ir ielādēta, vai nē.
var check_image= function(number)
{

  now= pictures.eq(number);
  prev= pictures.eq((number-1 + pictures.length - 1) % (pictures.length - 1));
  next= pictures.eq((number+1) % (pictures.length - 1));
  pics= [prev,now,next];

  for(i = 0; i<3;i++)
  {
    if (!(pics[i].children('img').attr('data-src') == pics[i].children('img').attr('src')))
    {
       pics[i].children('img').attr('src', pics[i].children('img').attr('data-src'));
    }
  }
}


    $('#picture-previous, #picture-next').on({
        mousedown : function(e)
        {
            return e.preventDefault();
        },
        select : function(e)
        {
            return e.preventDefault();
        }
    });


    $(".item").on('click', function(e){
        e.preventDefault();
        $('#thumbnails img').removeClass("active_image");
        src= ($(this).children('img').attr('src')).replace("thumb/","");
        current= $(this).children('img').data('sequence');
        check_image(current);
        back.attr('src',src).trigger('scale');
       location();
    });



            }

            var videos = $('#video-select');

            if (videos.length == 1)
            {
                var video_frames = $('#flasher iframe').hide();

                if (window.location.hash.length > 0)
                {
                    $(window.location.hash).show();

                    console.log( $('a', videos).filter('[href="'+ window.location.hash +'"]') );

                    $('a', videos).filter('[href="'+ window.location.hash +'"]').addClass('active');
                }
                else
                {
                    video_frames.first().show();
                    $('a', videos).first().addClass('active');
                }

                $('a', videos).on({
                    click : function()
                    {
                        $('a', videos).removeClass('active');

                        var href = $(this).attr('href');

                        // hide visibles
                        video_frames.hide().each(function() {
                            this.contentWindow.postMessage('{"event" : "command", "func" : "pauseVideo", "args" : ""}', '*');
                        });

                        // show current
                        $( href ).show();

                        $(this).addClass('active');
                    }
                });
            }
        });
