//$(document).ready(function(){
//    $("#content-1").mThumbnailScroller({
//        type:"click-50",
//        theme:"buttons-out"
//    });
//});
//
$(document).ready(function(){
    $('#content-1').mThumbnailScroller({type: "hover-50"});
});

